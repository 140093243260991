.custom-card {
    min-height: 455px;
    padding: 20px;
    border: 2px solid #D9D9D9;
    border-radius: 10px !important;
    box-shadow:  0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 1%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
    p.MuiTypography-root {
        font-size: 1.5rem;
        color: #39C7C2;
    }
    div.MuiTypography {
        font-weight: bold !important;
    }
    span {
        color: #39C7C2;
        margin: 5px;
    }
}

.current {
    color: #FFF !important;
    background-color: #39C7C2 !important;
    border: 2px solid #39C7C2;
    box-shadow:  0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 1%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
    p.MuiTypography-root {
        color: #FFF !important;
    }
    span {
        color: #FFF;
    }
}

span {
    font-size: 12px;
}

.amount {
    font-weight: bold !important;
}