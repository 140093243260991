* {
    font-family: "Libre Franklin" !important;
}

.mvr-form * {
    text-transform: capitalize;
}

.driver-title {
    font-weight: 700 !important;
    margin: 0 0 10px 10px !important;
}

.py-0 {
    padding: 0 16px 0 16px !important;
    span {
        text-transform: uppercase;
    }
}

.pb-0 {
    padding: 16px 16px 0 16px !important;
}

.mvr-form {
    border: 2px solid #39c7c2 !important;

    .MuiIconButton-label svg {
        color: #39c7c2;
    }

    .children {
        border: none !important;
    }

    .table-container {
        padding: 0 22px;
        .accidents {
            padding: 0 15px;
        }
        .no-violations {
            padding: 30px 0 0 0;
            font-size: 1.2rem;
        }
    }
}

.split {
    margin: 0 !important;
}

.not-elegible {
    text-align: center;
    color: #FF6C1A;
    font-weight: 700 !important;
}

.text-error {
    font-weight: bold !important;
}
.text-error, .text-accidents {
    color: #9D0707 !important;
}

.driver {
    display: flex;
    flex-direction: column;
    .driver-title strong {
        padding: 0 22px;
    }
}

.extra-padding {
    padding: 0 6px;
}

.summary .MuiAccordionSummary-content {
    display: block;
}