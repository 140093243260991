.MuiSelectTest {
    color: transparent;
    input {
      opacity: unset !important;
      border: none;
      background: white;
      text-align: center;
    }
  }

  .sharedSize {
    height: 32px;
    vertical-align: top;
    display: inline-block;
  }

  .main {
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .flex1 {
    flex: 1.5;
    position: relative;
    @media (max-width:760px) {
      min-width: 58%;
      margin-bottom: 15
    }
  }
  .image {
    // ...sharedSize
    width: 45px;
    background-size: auto;
    background-repeat: no-repeat;
    // backgroundImage: `url(${props.image})`
  }
  .title {
    line-height: 1.3;
    // color: `${props.isAdjust ? black : #39c7c2}`;
    color: #39c7c2;
    height: 32px;
    vertical-align: top;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    width: calc(100% - 80px);
    * {
      height: 100%;
      display: flex;
      align-content: center;
      flex-direction: column;
      justify-content: center;
    }
    // [theme.breakpoints.down(md)]: {;
    //   fontSize: 11px;
    // }
    // [theme.breakpoints.down(sm)]: {;
    //   fontSize: 10px;
    // }
  }
  .flex2 {
    flex: 1;
    max-width: 43%;
    @media (max-width:760px) {
      max-width: 42%;
    }
  }
  .question {
    top: 3px;
    right: 7px;
    color: #e2e2e2;
    position: absolute;
    display: inline-block;
  }
  .select {
    width: 100%;
    text-align: center;
    .MuiSelect-select {
      padding: 5px;
      background-color: initial;
    }
    fieldset {
      top: 0px;
      bottom: -2px;
    }
    .MuiSelect-icon {
      position: relative;
      right: 0;
      margin-left: -24px;
      @media (max-width: 780px) {
        margin-left: -12px;
      }
      @media (max-width: 420px) {
        margin-left: -4px;
      }
    }
  }
  .errorMessage {
    font-size: 0.57rem;
  }