.info {
    border-radius: 10px;
    padding: 10px;
    border: 2px solid #D9D9D9;
    color: #39C7C2;    
    text-align: center;
    span {
        font-size: 16px !important;
    }
}

.btn-container {
    align-items: center;
    padding: 40px 140px;

    .back-btn {
        background-color: #FFF;
        color: #39C7C2;
        margin-right: 5px;
    }
    
    .update-btn {
        background-color: #39C7C2;
        color: #FFF;
        margin-left: 5px;
    }
}

.extraLarge {
    height: 385px;
    padding: 75px 16px;
}

