.yes-btn {
  border: 1px solid #E5E5E5;
  padding: 5px 30px;
  text-transform: capitalize;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 11px;
  color: #000;
  :hover {
    transition: 0.1s ease;
    // font-size: 11px;
    // background-color: #EEEEEE;
    font-weight: bold;
    @media (max-width:740px) {
      color: #ffffff;
      background-color: #FF6C1A;
      border-radius: 8;
    }
  }
}
.no-btn {
  border: 1px solid #E5E5E5;
  padding: 5px 30px;
  text-transform: capitalize;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 11px;
  color: #000;
  :hover {
    transition: 0.1s ease;
    // font-size: 11px;
    // background-color: #EEEEEE;
    font-weight: bold;
    @media (max-width:740px) {
      color: #ffffff;
      background-color: #FF6C1A;
      border-radius: 8;
    }
  }
}

.active {
  color: #ffffff !important;
  font-weight: bold !important;
  background-color: #FF6C1A !important;
}

.not-active {
  background-color: #E5E5E5;
  font-weight: 500;
  padding: 5px 25px;
  
}

.yes-btn, .no-btn, .active, .not-active {
  border-radius: 8 !important;
  width: 50%;
}

.mb-10 {
  margin-bottom: 10px;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #dddddd !important;
}