.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;       
}
.flexBetween {
    display: flex;
    justify-content: space-between; 
    align-items: center;      
}
.page {
    width: 100%;
    height: 100vh;
}
.thanks-card {
    padding: 2rem 7rem;
    max-width: 860px;
}
.inherit {
    color: inherit;
    font-size: inherit;
    font-family: inherit;
}

    .container {
      width: 100%;
      height: 100%;
      // display: flex;
      justify-content: center;
    };
    .content {
      width: 100%;
      text-align: center;
      @media (max-width: 425px) {
        width: 95%
      }
    }
    .icon {
      display: flex;
      justify-content: center
    }
    .thanks-image {
      height: 50px ;
      width: 120px;
      margin: 0 auto;
      @media (max-width: 414px) {
        width: 85px;
      }
    }
    .paragraph {
      margin-top: 20px;
      text-align: center;
      font-size: 16.5px;
      font-weight: bold;
      color: gray;
      @media (max-width:740px) {
        font-size: 10px
      }
    }
    .policyNumber {
      font-size: clamp(1rem, 4vw, 40px);
      font-weight: bold;
      background-color: #eeedef;
      border-radius: 50px;
      margin: 0.25em 0
    }
    .thanks-title {
      font-weight: bold;
      font-size: clamp(0.75rem, 3vw, 40px);
      margin: 1em 0
    };
    .yourPolicy {
      border: none;
      //width: 18em;
      text-align: center;
      font-size: 2.2rem;
      @media (max-width: 1200px) {
        width: 15em;
        font-size: 1.8rem
      }
      @media (max-width: 820px) {
        width: 12em;
        font-size: 1.2rem
      }
    }
    .contentCopy {
      width: 35px;
      height: 40px;
      @media (max-width: 820px) {
        width: 30px;
        height: 30px
      }
      @media (max-width: 540px) {
        width: 20px;
        height: 25px
      }
      @media (max-width: 310px) {
        display: none
      }
    }
    .btnContentCopy {
      border: none;
      &:hover {
        cursor: pointer
      }
    }
    .btnpdf {
      border: 2px solid #7f7f7f;
      text-align: center;
      border-radius: 15px;
      background-color: #eeedef;
      display: flex;
      justify-content: space-evenly;
      margin-top: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 50px;
      padding-right: 50px;
      @media (max-width:740px) {
        font-size: 12px;
      }
      width: 100%
    }
    .btnGoToDashboard {
      border: 2px solid #7f7f7f;
      align-items: center;
      border-radius: 15px;
      background-color: #eeedef;
      display: flex;
      justify-content: space-evenly;
      margin-top: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 50px;
      padding-right: 50px;
      @media (max-width:740px) {
        font-size: 12px;
      }
      width: 100%
    }
    .btnCoteriePolicy {
      border: 5px;
      border-color: #7f7f7f;
      border-radius: 15;
      background-color: #37c3b8;
      color: #ffff;
      margin-top: 20px;
      padding-top: 12px;
      padding-left: 50px;
      padding-right: 50px;
      @media (max-width:740px) {
        font-size: 12px;
      }
      width: 100%
    }
    .btnInsurance {
      border: 2px solid #7f7f7f;
      border-radius: 15px;
      background-color: #eeedef;
      display: flex;
      justify-content: space-evenly;
      height: 3rem;
      margin-top: 20px;
      padding-top: 12px;
      padding-left: 50px;
      padding-right: 50px;
      @media (max-width:740px) {
        font-size: 12px;
      }
      width: 100%
    }
    .btnKoala {
      width: 40px;
      height: 40px;
      @media (max-width: 500px) {
        display: none
      }
    }
    .imgdownload {
      height: 35px;
      width: 35px;
      @media (max-width: 820px) {
        height: 30px;
        width: 30px;
      }
      @media (max-width: 500px) {
        display: none
      }
    }
    .goToDashboard {
      height: 35px;
      width: 35px;
      @media (max-width: 820px) {
        height: 30px;
        width: 30px;
      }
      @media (max-width: 500px) {
        display: none
      }
    }
    .btnCoterie {
      border: 2px solid #7f7f7f;
      border-radius: 30px;
      background-color: #eeedef;
      margin-top: 20px;
      padding-top: 13.5px;
      padding-bottom: 13.5px;
      padding-left: 73px;
      padding-right: 73px;
      @media (max-width:740px) {
        font-size: 12px;
      }
    }
    .mount {
      font-size: 60px;
      color: #ff6c1a;
      font-weight: bold;
      @media (max-width:740px) {
        font-size: 32px;
      }
    }
    .goToAgent {
      border: 2px solid #7f7f7f;
      border-radius: 15px;
      display: flex;
      justify-content: space-evenly;
      height: 3rem;
      margin-top: 20px;
      padding-top: 12px;
      padding-left: 50px;
      padding-right: 50px;
      @media (max-width:740px) {
        font-size: 12px;
      }
      & a {
        color: #7f7f7f;
        font-weight: bold;
        text-decoration: none
      }
      width: 100%
    }
    hr {
      border: 1.25px solid black;
      margin: 2rem 0;
      width: 100%
    }
    .text {
      margin: 1.75rem 0
    }
    .text2 {
      font-size: 0.75rem;
      text-align: right;
      margin: 0.25rem 0;
      color: #545454
    }
    .passwordInput {
      border: 2px solid #eeedef;
      border-radius: 10px;
      padding: 0.5rem
    }
    .input1 {
      border: none;
      width: 80%
    }
    .input2 {
      border: 2px solid #545454;
      border-radius: 30px;
      color: #545454;
      width: 20%
    }
    .text3 {
      font-size: 0.75rem;
      margin: 0.5rem 0;
      color: #545454
    }