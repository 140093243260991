.custom-dialog {
    overflow: unset !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
.MuiBox-root[class*="MuiBox-root-"], .MuiBox-root[class*="MuiBox-root-"] form, .mta-container form {
    padding-bottom: 0 !important;
}

.next-btn {
    background-color: #FF6C1A;
    border: 2px solid #FF6C1A;
    border-radius: 5px;
    width: 100%;
    color: white;
    padding: 10px;
    margin-bottom: 10px;
}

.next-btn.not-active {
    background-color: #E5E5E5;
    border: #E5E5E5;
    font-weight: 500;
}

.text-error {
    color: #9D0707;
}

.mb-10 {
    margin-bottom: 10px !important;
}