.pouch-label-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% 0;
}

.pouch-label-title {
  font-size: 45px;
}

.pouch-label-text {
  font-size: 22px;
  color: grey;
}

@media (max-width: 1024px) {
  .pouch-label-title {
    font-size: 50px;
  }
}

@media (max-width: 768px) {
  .pouch-label-container {
    margin-left: 1%;
  }

  .pouch-label-title {
    font-size: 40px;
    font-weight: bold;
  }
}

@media (max-width: 414px) {
  .pouch-label-container {
    margin-left: 12%;
    width: 80%;
  }

  .pouch-label-title {
    font-size: 36px;
    font-weight: bold;
  }

  .pouch-label-text {
    display: none;
  }
}

@media (max-width: 375px) {
  .pouch-label-container {
    margin-left: 11%;
    width: 80%;
  }

  .pouch-label-title {
    font-size: 32px;
    font-weight: bold;
  }
}

@media (max-width: 320px) {
  .pouch-label-container {
    margin-left: 7%;
    width: 95%;
  }
}
