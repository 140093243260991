.PouchCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 270px;
  height: 370px;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  margin-left: 1%;
  margin-right: 1%;
}

.PouchCard > div:first-of-type{
  width: 185px !important;
  height: 175px !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.PouchCard > div > svg {
  width: 185px;
  height: 175px;
  text-align: center;
}

.PouchCard > button {
  width: 180px;
  height: 50px;
  font-size: 20px;
  margin-top: 20px !important;
}

.PouchCard > div:nth-child(2) {
  font-size: 26px;
}
