* {
    font-family: "Libre Franklin" !important;
}

.goddie-card {
    border: 2px solid #39C7C2;
    border-radius: 20px;
    margin-top: 3rem;
    padding: 20px;
    font-size: 14px;

    strong {
        margin: 10px;
        font-size: 16px;
    }

    p {
        margin-top: 10px;
    }
    
    .orange {
        font-size: 14px;
        color: #FF6C1A;
        font-weight: bold;
    }
}