.result-list {
    z-index: 9999;
    width: 100%;
    position: absolute;
    background-color: #fff;
    border: 2px solid #dddddd
  }
.list-item {
    cursor: pointer;
    padding: 5px 0px;
    border-bottom: 1px solid #dddddd;
}