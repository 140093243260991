.stepperSideBar {
    position: sticky;
    top: 50px;
}

.sideImage {
    text-align: center;
}

.sideTitle {
    color: #000000;
    font-size: 27px;
    font-weight: 500;
    text-align: left;
}

.backButton {
    width: 100%;
    height: 50px;
    border: none;
    margin-top: 25px;
    border-radius: 12px;
    color: #000000;
    font-weight: bold;
    background-color: #f7f7f7;
}

.nextButton {
    width: 100%;
    height: 50px;
    border: none;
    margin-top: 25px;
    border-radius: 12px;
    font-weight: 500;
    color: #ffffff;
    background-color: #ff6c1a;
}

.nextButtonDisabled {
    width: 100%;
    height: 50px;
    border: none;
    margin-top: 25px;
    border-radius: 12px;
    color: #000000;
    font-weight: bold;
    background-color: lightgrey;
}

.cardAgent {
    margin: 2rem 0 0 0;
    padding: 1rem;
    background-color: #D8D8D8;
    color: gray;
}