body {
  margin: 0;
  font-family: "Libre Franklin";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.mt-10 {
  margin: 20px 0 20px 0;
}

.ml-0 {
  margin-left: 0px !important;
}

.App {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.google-button {
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  padding: 20px 15px;
  background-color: #4285f4;
}

.temp-hidden {
  opacity: 0;
}

@media (max-width:740px) {
  .telematics {
    flex-direction: column;
  }

  .telematics div {
    width: 100%;
  }

  .telematics div button {
    width: 50%;
  }

}

.address-manually {
  color: black;
}
.address-manually:visited {
  color: black;
}