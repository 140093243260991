.yes-btn {
    border: 1px solid #E5E5E5;
    padding: 5px 30px;
    text-transform: capitalize;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 11px;
    color: #000;
    :hover {
      transition: 0.1s ease;
      // font-size: 11px;
      // background-color: #EEEEEE;
      font-weight: bold;
      @media (max-width:740px) {
        color: #ffffff;
        background-color: #FF6C1A;
        border-radius: 8;
      }
    }
  }
.no-btn {
    border: 1px solid #E5E5E5;
    padding: 5px 30px;
    text-transform: capitalize;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 11px;
    color: #000;
    :hover {
      transition: 0.1s ease;
      // font-size: 11px;
      // background-color: #EEEEEE;
      font-weight: bold;
      @media (max-width:740px) {
        color: #ffffff;
        background-color: #FF6C1A;
        border-radius: 8;
      }
    }
  }

  .active {
    color: #ffffff !important;
    font-weight: bold !important;
    background-color: #FF6C1A !important;
  }

  .not-active {
    background-color: #E5E5E5;
    font-weight: 500;
    padding: 5px 25px;
    
  }

  .yes-btn, .no-btn, .yes-telematics-btn, .no-telematics-btn, .active, .not-active {
    border-radius: 8 !important;
  }

  .yes-telematics-btn {
    width: 125px;
    
    :hover {
      // background-color: #EEEEEE;
      font-weight: bold;
      @media (max-width:740px) {
        color: #ffffff;
        background-color: #FF6C1A;
        border-radius: 8;
      }
    }
  }

  .no-telematics-btn {
    width: 125px;
    
    :hover {
      // background-color: #EEEEEE;
      font-weight: bold;
      @media (max-width:740px) {
        color: #ffffff;
        background-color: #FF6C1A;
        border-radius: 8;
      }
    }
  }

  .vin-title {
    padding: 5px;
    color: #000;
    font-size: 14px;
  }

  .search-vin-btn {
    margin-bottom: 2rem;
    border: none;
    background-color: #ffff;
    font-size: 10px;
    text-decoration: underline;
  }

  .search {
    position: 'absolute';
    z-index: 9;
    right: 10 !important;
    top: 12 !important;
    cursor: pointer !important;
    color: #FF6C1A !important;
    font-weight: bold;
    text-decoration: underline !important;
    :hover {
      color: #000;
      background-color: transparent;
    }
  }

  .vin-title {
    padding: 5px 5px 5px 15px;
    color: #000;
    font-size: 14px;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid #dddddd !important;
  }

  .label {
    left: 5px;
    top: -10px;
    border: none;
    padding: 0 5px;
    font-size: 0.7em;
    position: absolute;
    white-space: nowrap;
    background-color: #fff;
  }

  .search-input {
    width: 100%;
  }

  .vin-div {
    label {
      padding: 0;
    }
  }
  
  .px-22 {
    padding-left: 22px;
    padding-right: 22px;
  }

  .px-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .border-none {
    border: none !important;
  }

  .px-0 {
    padding-left: 0;
    padding-right: 0;
  }