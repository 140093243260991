.premium-info {
    .custom-colapse {
        .form {
            border: none;
            .customInputHidden {
                border: 1px solid #D9D9D9;
                border-radius: 5px;
            }
            .driver {
                .MuiTypography-root {
                    margin: 0 0 20px 0;
                    font-size: 1.5em;
                    font-style: normal;
                    font-weight: 700;
                }
            }
        }
    }
    svg.MuiSvgIcon-root.delete {
        display: none;
    }
}