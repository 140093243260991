.chargerbee_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;  
}

.chargerbee_container input::-webkit-outer-spin-button,
.chargerbee_container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.chargerbee_container input[type=number] {
    -moz-appearance: textfield;
}