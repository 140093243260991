.sapm-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sapm-header > div > img {
  width: 300px;
  height: 150px;
  margin: 3rem 0;
}

.sapm-header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sapm-header-title > div:nth-child(1){
  font-size: 2rem;
  color: #37c3b8;
  margin: 0.5rem 0;
}

.sapm-header-title > div:nth-child(2){
  color: #aaaa;
  margin: 1rem 0;
}
