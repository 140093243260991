.form-container {
    margin-bottom: 20px;

    .form {
        box-shadow: none !important;
        margin: 20px 0px;
        border-radius: 15px !important;
        border: 2px solid #dddddd;

        ::before {
            content: "something";
            display: none;
        }

        .summary {
            .MuiAccordionSummary-content {
                justify-content: space-between;
            }
        }

        .driver {
            display: flex;
            justify-content: space-between;
            padding-left: 6px;

            span {
                top: 2px;
                left: 8px;
                color: gray;
                position: relative;
                margin: 0 1rem;
            }

            // .vehicle-title {
            //     margin-left: 2rem;
            // }
        }

        .children {
            width: 100%;
            display: block;
        }

        .delete {
            color: rgba(0, 0, 0, 0.54);
        }
        .delete-btn {
            text-align: right;
        }
    }
}

.multi-item-card {
    .MuiPaper-root {
        padding: 20px;
    }
}