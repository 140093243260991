.sapm{
  display: flex;
  justify-content: space-around;
  margin: 7rem 20%;
}

.pm{
  display: flex;
  justify-content: space-between;
  margin: 0 0% 5.5rem 0%;
}

.sapm-container-on{
  border: 1px solid #37c3b8;
  box-shadow: -5px 12px 15px -5px #37c3b8;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  width: 350px;
  height: 400px;
}

.pm-container-on{
  border: 1px solid #FF6C1A;
  box-shadow: -5px 12px 15px -5px #FF6C1A;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  width: 265px;
  height: 255px;
}

.sapm-container-off{
  border: 1px solid #aaaa;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  width: 350px;
  height: 400px;
}

.pm-container-off{
  border: 1px solid #aaaa;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  width: 265px;
  height: 255px;
}

.sapm-container-top-line{
  width: 100%;
  background: #37c3b8;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  height: 20px;
  border: none;
}

.pm-container-top-line{
  width: 100%;
  background: #FF6C1A;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  height: 20px;
  border: none;
}

.sapm-container-header{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sapm-container-header > div:nth-child(1) {
  width: 8rem;
  height: 5rem;
  margin: 1rem 0;
  text-align: center;
}

.sapm-container-header > div:nth-child(2) {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

hr {
  width: 80%;
  margin: 0 auto;
}

.sapm-container-payer-info{
  display: flex;
  flex-direction: column;
  padding: 1.75rem;
}

.pm-container-payer-info{
  display: none;
}

.sapm-container-payer-info > div > label{
  width: 25%;
  margin-bottom: 1.25rem;
  font-weight: bold;
  color: #aaaa;
}

.sapm-container-payer-info > div > input[name='total']{
  color: #37c3b8;
  font-weight: bold;
}

.sapm-container-payer-info > div > input{
  border: none;
  text-align: end;
  background-color: #fff;
  font-weight: bold;
}

.sapm-container-circle{
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
}

.pm-container-circle{
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 42px;
}

.sapm-container-circle-selector-on {
  content: '';
  display: block;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #37c3b8;
  margin: 8px;
}

.pm-container-circle-selector-on {
  content: '';
  display: block;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #FF6C1A;
  margin: 8px;
}

.sapm-container-circle-selector-off {
  content: '';
  display: block;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 1.5px solid #aaa;
  background-color: #fff;
  margin: 8px;
}

.pm-container-circle-selector-off {
  content: '';
  display: block;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 1.5px solid #aaa;
  background-color: #fff;
  margin: 8px;
}

@media (max-width: 1024px){
  .sapm-container-on{
    width: 250px;
    height: 300px;
  }
  .sapm-container-off{
    width: 250px;
    height: 300px;
  }
  .sapm-container-payer-info{
    padding: 1rem;
  }
  .sapm-container-payer-info > div > label{
    margin-bottom: 0;
  }
  .sapm-container-payer-info > div > label:nth-child(1){
    text-align: center;
  }
  .sapm-container-payer-info > div > input{
   width: 70%;
  }
  .sapm-container-payer-info > div > input:nth-child(1){
   width: 42%;
  }
  .sapm-container-header > div:nth-child(1) {
    margin: 0.5rem 0;
  }
}

@media (max-width: 768px){
  .sapm {
    margin: 5rem 8%;
  }
}

@media (max-width: 600px){
  .sapm{
    flex-direction: row;
    align-items: baseline;
    margin: 1rem 0%;
  }
  .sapm-container-on{
    width: 150px;
    height: 210px;
  }
  .sapm-container-off{
    width: 150px;
    height: 210px;
  }
  hr {
    display: none;
  }
  .sapm-container-payer-info{
    display: none;
  }
}
